
















































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import BannerDropdown from '../../components/BannerDropdown/index.vue'
import BannerTextInput from '../../components/BannerTextInput/index.vue'
import ImageUpload from '@/app/ui/components/ImageUpload/index.vue'
import BannerDatePicker from '../../components/BannerDatePicker/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import ConfirmationIcon from '@/app/ui/assets/icon_confirmation.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import dayjs from 'dayjs'
import {
  required,
  maxLength,
  url as urlValidation,
  or,
  and,
  between,
} from 'vuelidate/lib/validators'
import {
  FormInterface,
  typeOptions,
  showTimeOptions,
  locationOptions,
  EventBus,
  EventBusConstants,
  EnumFilterByStatus,
  EnumShowTimeLabel,
  EnumShowTimeOptions,
  PopUpBannerForm,
} from '@/app/infrastructures/misc'
import { PopUpBanner } from '@/domain/entities/Banner'
import controller from '@/app/ui/controllers/PopUpBannerController'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  mixins: [validationMixin],
  components: {
    LoadingOverlay,
    BannerDropdown,
    BannerTextInput,
    ImageUpload,
    BannerDatePicker,
    Button,
    ConfirmationIcon,
    Modal,
  },
})
export default class PopUpBannerEdit extends Vue {
  MAX_SIZE = 5000
  confirmationModal = false
  isImageValid = false
  todayDate = dayjs().format('YYYY-MM-DD')
  isImageFromFile = false
  form: FormInterface = {
    type: null,
    title: '',
    location: null,
    image: <File[] | Array<undefined>>[],
    url: '',
    timer: 3,
    showTime: null,
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
  }
  hasChanged = {
    type: false,
    title: false,
    location: false,
    url: false,
    timer: false,
    showTime: false,
    startDate: false,
    endDate: false,
    startTime: false,
    endTime: false,
  }
  typeOptions = typeOptions
  locationOptions = locationOptions
  showTimeOptions = showTimeOptions
  controller = controller

  @Validations()
  validations(): Record<string, unknown> {
    return {
      form: {
        type: {
          required,
        },
        title: {
          required,
          maxLength: maxLength(70),
        },
        location: {
          required,
        },
        url: {
          url: and(
            required,
            or(urlValidation, (value: string) =>
              value.startsWith('lionparcel://app')
            )
          ),
        },
        timer: {
          required,
          between: between(3, 10),
        },
        showTime: {
          required,
        },
        startDate: {
          required,
        },
        endDate: {
          required,
        },
        startTime: {
          required,
        },
        endTime: {
          required,
        },
      },
    }
  }

  created(): void {
    controller.getPopUpBannerDetail(this.$route.params.id)

    EventBus.$on(EventBusConstants.UPDATE_POPUP_BANNER_SUCCESS, () => {
      this.$router.push({
        name: `PopUpBannerDetailPage`,
        params: { id: this.$route.params?.id },
      })
    })
  }

  get popUpBannerDetail(): PopUpBanner {
    return controller.popUpBannerDetail
  }

  private showTimeLabel(type: string): string {
    if (type.toUpperCase() === EnumShowTimeOptions.ALWAYS_SHOW) {
      return EnumShowTimeLabel[EnumShowTimeOptions.ALWAYS_SHOW]
    } else if (type.toUpperCase() === EnumShowTimeOptions.ONLY_ONCE) {
      return EnumShowTimeLabel[EnumShowTimeOptions.ONLY_ONCE]
    } else {
      return EnumShowTimeLabel[EnumShowTimeOptions.ONCE_A_DAY]
    }
  }

  @Watch('popUpBannerDetail')
  onBannerDetailChanged(val: PopUpBanner): void {
    if (
      val.status?.toUpperCase() === EnumFilterByStatus.CANCELLED ||
      val.status?.toUpperCase() === EnumFilterByStatus.EXPIRED
    ) {
      this.$router.push(`/banner/pop-up-banner`)
    }
    this.form = {
      type: {
        label: String(val.type).toUpperCase(),
        value: String(val.type).toLowerCase(),
      },
      title: val.title || '',
      location: {
        label: Utils.toCapitalize(String(val.location).replace('_', '')),
        value: String(val.location).toLowerCase(),
      },
      image: [new File([], <string>val.image)],
      url: val.url || '',
      timer: Number(val.timer),
      showTime: {
        label: this.showTimeLabel(String(val.showTime)),
        value: String(val.showTime).toLowerCase(),
      },
      startDate: val.startDate ? dayjs(val.startDate).format('YYYY-MM-DD') : '',
      endDate: val.endDate ? dayjs(val.endDate).format('YYYY-MM-DD') : '',
      startTime: val.startDate ? dayjs(val.startDate).format('HH:mm:ss') : '',
      endTime: val.endDate ? dayjs(val.endDate).format('HH:mm:ss') : '',
    }
  }

  get bannerImage(): string[] {
    if (this.isImageFromFile && this.form.image.length > 0) {
      return [URL.createObjectURL(this.form.image[0])]
    } else if (this.form.image[0]) {
      return [this.form.image[0].name]
    } else {
      return []
    }
  }

  private onFileChange($event: File): void {
    this.isImageFromFile = true
    this.form.image?.splice(0, 1, $event)
    if ($event) {
      this.isImageValid = true
      this.isImageFromFile = true
    } else {
      this.isImageValid = false
      this.isImageFromFile = false
    }
  }

  private onRemoveImage(index: number): void {
    this.form.image?.splice(index, 1)
  }

  private onEditBanner(): void {
    if (!this.$v.form.$invalid && this.form.image[0]) {
      this.confirmationModal = false
      const payload: PopUpBannerForm = {
        id: this.$route.params.id,
        type: this.form.type?.value,
        title: this.form.title,
        location: this.form.location?.value,
        image: this.form.image[0],
        url: this.form.url,
        timer: this.form.timer,
        showTime: this.form.showTime?.value,
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        startTime: this.form.startTime,
        endTime: this.form.endTime,
        isUpdatedImage: this.isImageFromFile,
      }
      controller.updatePopUpBanner(payload)
    } else {
      Vue.notify({
        title: 'Edit Pop Up Banner',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
    this.hasChanged = {
      type: true,
      title: true,
      location: true,
      url: true,
      timer: true,
      showTime: true,
      startDate: true,
      endDate: true,
      startTime: true,
      endTime: true,
    }
  }

  private isStartDateMoreEndDate(): void {
    this.hasChanged.startDate = true
    if (this.form.startDate > this.form.endDate) {
      this.form.endDate = ''
    }
  }

  private isStartTimeMoreEndTime(
    type: 'startTime' | 'endTime' = 'startTime'
  ): void {
    this.hasChanged[type] = true
    if (
      this.form.startDate === this.form.endDate &&
      this.form.startTime >= this.form.endTime
    ) {
      this.form.endTime = ''
    }
  }

  get isActive(): boolean {
    return (
      controller.popUpBannerDetail.status?.toUpperCase() ===
      EnumFilterByStatus.ACTIVE
    )
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.UPDATE_POPUP_BANNER_SUCCESS)
  }
}
